import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "../styles/meindach.css"
import Card from "../components/cardMeinDach"
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa"

import imgMeinDach01 from "../assets/meindach/meindach01.jpg"
import imgMeinDach02 from "../assets/meindach/meindach02.jpg"
import imgMeinDach03 from "../assets/meindach/meindach03.jpg"
import imgMeinDach04 from "../assets/meindach/meindach04.jpg"
import imgMeinDach05 from "../assets/meindach/meindach05.jpg"
import imgMeinDach06 from "../assets/meindach/meindach06.jpg"
import imgMeinDach07 from "../assets/meindach/meindach07.jpg"
import imgMeinDach08 from "../assets/meindach/meindach08.jpg"
import imgMeinDach09 from "../assets/meindach/meindach09.jpg"
import imgMeinDach10 from "../assets/meindach/meindach10.jpg"
import imgMeinDach11 from "../assets/meindach/meindach11.jpg"

const ArrowUp = props => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FaArrowAltCircleUp
        color="#E2001A"
        size="25px"
        style={{ "margin-left": "-30px" }}
      />
    </div>
  )
}

const ArrowDown = props => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FaArrowAltCircleDown
        color="#E2001A"
        size="25px"
        style={{ "margin-right": "-30px" }}
      />
    </div>
  )
}

export default class List_Partner extends Component {
  render() {
    const settings = {
      className: "slider",
      arrows: true,
      autoplay: false,
      autoplaySpeed: 1000,
      centerMode: false,
      dots: true,
      infinite: false,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 500,
      variableWidth: false,
      vertical: true,
      verticalSwiping: true,
      nextArrow: <ArrowDown />,
      prevArrow: <ArrowUp />,
    }
    return (
      <div>
        <Slider {...settings}>
          <div>
            <Card
              Image={imgMeinDach01}
              Title="1. Projekt in Angriff nehmen"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach02}
              Title="2. Dachdecker kontaktieren"
              Text="(Planungsbüro beauftragen)"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach03}
              Title="3. Dacheindeckung konfigurieren"
              Text="(Beratungsgespräch mit dem Handwerker)"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach04}
              Title="4. Angebote anfordern"
              Text="(Vergleichen und Unterschiede erkennen)"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach05}
              Title="5. Finanzierungsplan aufstellen"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach06}
              Title="6. Bauantrag / -anzeige stellen"
              Text="(Fachplaner)"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach07}
              Title="7. Auftrag erteilen"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach08}
              Title="8. Termin vereinbaren"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach09}
              Title="9. Baustelle überwachen"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach10}
              Title="10. Handwerker loben"
              Text="&nbsp;"
            />
          </div>
          <div>
            <Card
              Image={imgMeinDach11}
              Title="11. Ein neues Zuhause beziehen"
              Text="&nbsp;"
            />
          </div>
        </Slider>
      </div>
    )
  }
}

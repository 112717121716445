import React from "react"
import { Card } from "react-bootstrap"

import "../styles/meindach.css"

export default props => (
  <div className="d-flex justify-content-center">
    <Card border="light" className="meindach-card">
      <Card.Body>
        <Card.Title>
          <h4 className="text-center">{props.Title}</h4>
        </Card.Title>
        <Card.Text>
          <h5 className="text-center">{props.Text}</h5>
        </Card.Text>
      </Card.Body>
      <Card.Img variant="bottom" src={props.Image} />
    </Card>
  </div>
)

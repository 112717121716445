import React, { Component } from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselIndex"
import ContactBox from "../components/boxContact"
import ListeMeinDach from "../components/listMeinDach"
import ListePartnerGrid from "../components/listPartnerGrid"
import ListePartnerSlider from "../components/listPartnerSlider"

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktop: false, //This is where I am having problems
    }

    this.updatePredicate = this.updatePredicate.bind(this)
  }
  componentDidMount() {
    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate)
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1200 })
  }

  render() {
    const isDesktop = this.state.isDesktop

    return (
      <div>
        <Header />
        <Carousel />
        <div className="contentbox white">
          <Container className="text-center">
            <h1>Mein neues Dach</h1>
            <hr />
          </Container>
        </div>
        <div className="arrow-up gray" />
        <div className="contentbox gray">
          <Container className="text-center">
            <h2>So geht's!</h2>
            <hr />
            <p />
            <ListeMeinDach />
          </Container>
        </div>
        <ContactBox />
        <div className="arrow-up gray" />
        <div className="contentbox gray foot">
          <Container className="text-center">
            <h2>Unsere Partner</h2>
            <hr />
          </Container>
          <p />
          {isDesktop ? <ListePartnerGrid /> : <ListePartnerSlider />}
        </div>
        <Footer />
      </div>
    )
  }
}
